.endDateStyleD{
    margin-left: 75px;
}
.startDateStyleD{
    margin-left: 150px;
}
.reportTypeD{

}
.select-container .react-select__menu {
    max-height: none !important;  /* To allow the dropdown to take as much height as needed */
    overflow-y: hidden !important; /* Remove vertical scrollbar */
  }

  .content-wrapper_d {
    overflow-y: hidden !important; /* Removes vertical scrollbar */
    overflow-x: hidden !important; /* Removes horizontal scrollbar if needed */
  }
  .width141x {
    width: 141px;
}
.endDateStyleDL{
    margin-left:85px;;
}