.FilterDivSection .groupStyle{
    margin-left: 10px !important;
  }
  .FilterDivSection .startDateStyle{
    margin-left: 23px !important;
  }
  .FilterDivSection .endDateStyle{
    margin-left: 73px !important;
  }

.endDateStyle{margin-left: 75px;}
.groupStyle{margin-left: 80px;}
.refcbStyle{margin-left: 100px;}

  .width92x{width: 110px;}

  .width62x{width: 80px;}
  .width140x{
     width: 130px;
   }

   .widthSelectInput{
     width: 190px;
   }
   .widthSelectInput200x{
     width: 220px;
   }

   .summaryDiv{
    width: 20% !important;
}
.parent  {
    overflow-y: hidden !important;
}
.column1 {
    float: left;
    width: 30.00% !important;
  }
  .column2 {
    float: left;
    width: 40.00% !important;
  }
  .column3 {
    float: left;
    width: 26.00% !important;
  }
  .filterCategoryShipCost{
    text-align: left;
  }
  .filterTitle{
    text-align: center;
  }
  .closeIconCommon{
    float: right;
    margin-left: 400px;
    margin-top: -20px;
  }


  
.filterValue{ padding: 8px 8px;}
.filterSpan{font-size :12px!important;
  padding: 4px 6px !important;
}
.filterValueStyle{margin-left: 120px;}

.body_div {
    background: #222;
  }
  
  .fileuploadcontainer {
    background: #fff;
  }
  
  .custom-file {
    width: 350px;
    height: 300px;
    border: 1px dotted #dedede;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .custom-file .custom-file-input {
    opacity: 0;
    height: 0;
    display: none;
  }
  
  .custom-file .custom-file-label {
    text-align: center;
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
  }
  
  .custom-file .placeholder {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  
  .margin-bottom {
    margin-bottom: 15px !important;
  }
  
  .margin-auto {
    margin: 0 auto;
  }
  
  .custom-file .placeholder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .successMsg {
    font-size: 18px;
    font-weight: bold;
    color: green;
    margin-left: 90px;
  }
  
  .errorMsg {
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-left: 90px;
  }
  
  .confirm-bg {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    bottom: 20px;
    width: 100%;
    height: 100%;
    background-color: #202020;
    opacity: 0.55;
    overflow: hidden;
    /* disable scrolling*/
    z-index: 2;
    /* higher than all other items, but lower than 
        the confirm box*/
  }
  
  .container1 {
    display: block;
    flex-direction: column;
    position: fixed;
    background-color: #202020;
    width: 230px;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    border-radius: 0.3rem;
    padding: 1rem;
    z-index: 5;
    /* Higher than the z-index of the background */
  }
  
  .confirmation-text {
    display: flex;
    color: white;
    margin: 0.5rem 0 2rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1.1rem;
  }
  
  .button-container {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
  }
  
  .confirmation-button,
  delete-button {
    display: inline-flex;
    background-color: #cc0000;
    color: white;
    padding: 0.7rem 1.4rem;
    border: none;
    border-radius: 0.3rem;
    font-size: 1rem;
  }
  
  .cancel-button {
    background-color: #999999;
  }
  
  .confirmation-button:hover {
    background-color: red;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background-color: #b2b2b2;
    cursor: pointer;
  }
  
  .delete-div {
    text-align: right;
    margin-right: 50px;
    margin-top: 10px;
  }
  
  .viewScreen {
    text-align: center !important;
  }
  
  .loaderDiv {
    text-align: center;
    flex: 1;
    justify-content: center;
    margin-top: 100px;
  }

  .datefilterDiv{margin-left: 500px;}
.content-wrapper{margin-top: 30px !important}
field { font-family:arial, sans-serif; border-color: #d9d9d9; border-top:solid 1px #c0c0c0; }
input.field{width:100%;}
.fieldwrapper{
white-space: nowrap;
}

.input-bar {
    display: table;
    width: 20%;
}

.input-bar-item {
    display: table-cell;
}

.input-bar-item > button {
    margin-left: 5px;
}

.width100 {
  width: 100%;
}
.width80 {
  width: 80%;
}
.delete-div{margin-left: 20px;}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.closeIconCSSTakeRate{
    margin:-27px;  
    float: right;

}

.summaryIcon{
    height: 30px !important;
    width:30px !important;
    cursor: pointer;
}
.summaryIconDiv{
    margin-left: 1100px;
}
.summaryDiv{
    /* float: right;
     margin-top: -185px;  */

     position: sticky;
     float: right;
     margin-top: -188px;

     /* position: inherit;
    float: right;
    margin-top: -431px; */

    border: solid 1px;
    padding: 55px;
    max-height: 440px;
    overflow-y: auto;
    /* height: 193px;
    width: 238px; */
    /* top: 80% !important; */
    width: 20% !important;
    height: 93% !important;
}
.summaryWrapperDiv{

}
#c1, #c2 {
    width: 33%;
}

#c3 {
    width: auto;
}

.column {
    float: left;
    width: 33.33%;
  }
  .column1 {
    float: left;
    width: 30.00% !important;
  }
  .column2 {
    float: left;
    width: 40.00% !important;
  }
  .column3 {
    float: left;
    width: 26.00% !important;
  }
  /* .columns:after {
    content: "";
    display: table;
    clear: both;
  } */
  .seperator {
    height: 100%;
    width: 1px;
    background: black;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 50%;
  }

.parent {background-color: lightgray; height: 100%; overflow: hidden;}
.parent .column {float: left; background-color: #fff; height: 100%; margin: 0.5%; padding: 4px;}
/* width: 32.25%; */


.datefilterDiv{margin-left: 500px;}
.content-wrapper{margin-top: 30px !important}
field { font-family:arial, sans-serif; border-color: #d9d9d9; border-top:solid 1px #c0c0c0; }
input.field{width:100%;}
.fieldwrapper{
white-space: nowrap;
}

.input-bar {
    display: table;
    width: 20%;
}

.input-bar-item {
    display: table-cell;
}

.input-bar-item > button {
    margin-left: 5px;
}

.width100 {
  width: 100%;
}
.delete-div{margin-left: 20px;}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.loaderDiv{
    text-align: center;
    flex: 1;
    justify-content: center;
    margin-top: 100px;
}
.downloadReportBtn{
    margin-left:2px;
}

td[value=Brand] {
  font-weight: bold !important
}
td[value=Orders] {
  font-weight: bold !important
}
td[value='Unit Consumed'] {
  font-weight: bold !important
}
td[value='Units per Cart'] {
  font-weight: bold !important
}
.con_date_for_report{margin-left: 150px;}

.con_date_for_report{
  
}
.con_date_for_report_type{

}
.con_date_for_report_end{
  margin-left: 75px;
}
.datePickerCon{width: 130px;
  height: 36px;}