.datefilterDiv{margin-left: 500px;}
.content-wrapper{margin-top: 30px !important}
field { font-family:arial, sans-serif; border-color: #d9d9d9; border-top:solid 1px #c0c0c0; }
input.field{width:100%;}
.fieldwrapper{
white-space: nowrap;
}

.input-bar {
    display: table;
    width: 20%;
}

.input-bar-item {
    display: table-cell;
}

.input-bar-item > button {
    margin-left: 5px;
}

.width100 {
  width: 100%;
}
.delete-div{margin-left: 20px;}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.closeIconCSSTakeRate{
    margin:-27px;  
    float: right;

}

.summaryIcon{
    height: 30px !important;
    width:30px !important;
    cursor: pointer;
}
.summaryIconDiv{
    margin-left: 1100px;
}
.summaryDiv{
    /* float: right;
     margin-top: -185px;  */

     position: sticky;
     float: right;
     margin-top: -188px;

     /* position: inherit;
    float: right;
    margin-top: -431px; */

    border: solid 1px;
    padding: 55px;
    max-height: 440px;
    overflow-y: auto;
    /* height: 193px;
    width: 238px; */
    /* top: 80% !important; */
    width: 20% !important;
    height: 93% !important;
}
.summaryWrapperDiv{

}
#c1, #c2 {
    width: 33%;
}

#c3 {
    width: auto;
}

.column {
    float: left;
    width: 25%;
  }
  .column1 {
    float: left;
    width: 30.00% !important;
  }
  .column2 {
    float: left;
    width: 40.00% !important;
  }
  .column3 {
    float: left;
    width: 26.00% !important;
  }
 
  /* .columns:after {
    content: "";
    display: table;
    clear: both;
  } */
  .seperator {
    height: 100%;
    width: 1px;
    background: black;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 50%;
  }

/* .parent {background-color: lightgray; height: 100%; overflow: hidden;}
.parent .column {float: left; background-color: #fff; height: 100%; margin: 0.5%; padding: 4px;} */


.side1 {
  /* background-color: lightgray; */
  /* height: 30% !important;
  overflow: hidden;
  top: 36% !important; */
  /* right: -6%; */
  /* width: 18% !important;
  right: 17% !important; */
}
.side1{
  position: absolute;
    right: -24% !important;
    padding: 3px;
    transform: translate(-50%, -50%);
    border: 2px solid rgb(0, 0, 0);
    top: 22% !important;
    width: 44%;
    height: 20% !important;
    max-height: 550px;
    overflow-y: auto;
}
.side1 .column {float: left; background-color: #fff; height: 20%; margin: 0.5%; padding: 4px;}

/* .side2 {background-color: lightgray; height: 80%; overflow: hidden;top: 65% !important;} */
.side2{
  position: absolute;
    right: -24% !important;
    padding: 3px;
    transform: translate(-50%, -50%);
    border: 2px solid rgb(0, 0, 0);
    top: 60% !important;
    width: 44%;
    height: 50% !important;
    max-height: 550px;
    overflow-y: auto;
}
.side2 .column {float: left; background-color: #fff; height: 80%; margin: 0.5%; padding: 4px;}

.side2 .column1 {
  float: left;
  width: 15.00% !important;
}
.side2 .column2 {
  float: left;
  width: 25.00% !important;
}
.side2 .column3 {
  float: left;
  width: 25.00% !important;
}
.side2 .column4 {
  float: left;
  width: 25.00% !important;
}
/* width: 32.25%; */

.brandStyle{
  margin-left: 78px;
}
.reporTypelLbel{
    margin-left: 20px;
}
#content-wrapper-1 {
    top: -95px !important;
}
.sourceOfOrderCl{
  margin-left: 5px;
}
.orderTypeCl{
 margin-left: 108px;
}