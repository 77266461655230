.successMsg{
    font-size: 18px;
    font-weight: bold;
    color:green;
    margin-left: 90px;
}
.errorMsg{
    font-size: 18px;
    font-weight: bold;
    color:red;
    margin-left: 90px;
}

.confirm-bg {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    bottom: 20px;
    width: 100%;
    height: 100%;
    background-color: #202020;
    opacity: 0.55;
    overflow: hidden; /* disable scrolling*/
    z-index: 2; /* higher than all other items, but lower than 
      the confirm box*/
  }

  .container1 {
    display: block;
    flex-direction: column;
    position: fixed;
    background-color: #202020;
    width: 230px;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    border-radius: 0.3rem;
    padding: 1rem;
    z-index: 5; /* Higher than the z-index of the background */
  }
  
  .confirmation-text {
    display: flex;
    color: white;
    margin: 0.5rem 0 2rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1.1rem;
  }
  
  .button-container {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
  }
  
  .confirmation-button, delete-button {
    display: inline-flex;
    background-color: #cc0000;
    color: white;
    padding: 0.7rem 1.4rem;
    border: none;
    border-radius: 0.3rem;
    font-size: 1rem;
  }
  
  .cancel-button {
    background-color: #999999;
  }
  
  .confirmation-button:hover {
    background-color: red;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background-color: #b2b2b2;
    cursor: pointer;
  }
  .delete-div{
    text-align: right;
    margin-right: 50px;
    margin-top: 10px;
  }

  .downloadExcelDiv{
    text-align: center;
  }
  /* .uploadMsg{
    margin-left: 300px !important;
} */
.viewScreen{text-align: center !important;}