
/* .groupStyle{
    margin-left: 0px !important;
} */
.errorMsg{
    margin-left: 40px !important;
}


.startDateStyle_combined{
    margin-left: 75px !important;
}
.widthSelectInputCom{
    width: 150px;
}


.width122x {
    width: 122px;
}
.endDateStyle_combined{
    margin-left: 67px !important;
}

.chargebackStyle_combined {
    margin-left: 45px;
}
/* .MuiSlider-root{
    width:8% !important;
    padding: 0 !important;
  } */
  .MuiSlider-sizeMedium{
    width:8% !important;
    padding: 0 !important;
    position: fixed;
  }