
  
  /* tr[level="1"] {
    background-color: lightblue;
  }
  
  tr[level="2"] {
    background-color:lightcoral;
  } */

  tr[level="1"] {
    background-color:#FAA0A0;
  }
  
  tr[level="2"] {
    background-color:#FFFD8D;
  }

  .widthSelectInput{
    width: 220px;
    

  }

  .FilterDivSection{
    /* overflow: auto;
white-space: nowrap; */

  }

  .FilterDivSection .groupStyle{
    margin-left: 10px !important;
  }
  .FilterDivSection .startDateStyle{
    margin-left: 3px !important;
  }
  .FilterDivSection .endDateStyle{
    margin-left: 73px !important;
  }

