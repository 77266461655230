.pointerCls{
    cursor: pointer;
}
.border-a{
border:2px solid gray !important;
padding:2px
}


#navbar-wrapper .navbar{
    font-size:20px !important;
}

.homeIcon{
    cursor: pointer;
    font-size:40px !important;
}