/* tbody > tr:last-child>td{ 
    font-size:'37px';
    font-weight:'bold';
 }

 table :last-child >:last-child > td {
    text-align: center;
    font-size:'37px';
    font-weight: bold
 } */
.endDateStyle{margin-left: 75px;}
 .groupStyle{margin-left: 80px;}
 .refcbStyle{margin-left: 100px;}

   .width92x{width: 110px;}

   .width62x{width: 80px;}
   .width140x{
      width: 130px;
    }

    .widthSelectInput{
      width: 190px;
    }
    .widthSelectInput200x{
      width: 220px;
    }