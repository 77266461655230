
.body_div{
    background: #222;
}
/* .fileupload_div{width:80%;} */
.fileuploadcontainer{
    background: #fff;
    /* width: 60%; */
}

.custom-file {
    width: 350px;
    height: 300px;
    border: 1px dotted #dedede;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.custom-file .custom-file-input{
    opacity: 0;
    height: 0;
    display: none;
}

.custom-file .custom-file-label{
    text-align: center;
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
}

.custom-file .placeholder{
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.margin-bottom{
    margin-bottom: 15px !important;
}
.margin-auto{
    margin: 0 auto;
}

.custom-file .placeholder img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/* .uploadMsg{
    margin-left: 300px !important;
} */
.viewScreen{text-align: center !important;}