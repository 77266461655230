.headerH{
  text-align: center;
  font-size: 40px;
}

.uploadWatch{margin-left: 40% !important; margin-bottom: 20px;}
  .uploadMsg{
    margin-left: 30% !important;
}

.MuiTypography-caption{
  font-size: 1.75rem !important;
  font-weight: bold !important;
}
.MuiTablePagination-input{
  font-size: 1.75rem !important;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.MuiTypography-h6{
  font-size: 2rem !important;
  font-weight: bold !important;
}
  /* .Component-paginationSelectRoot-4 {
    margin: 0;
    margin-top: 12px !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
} */

/* .MuiTableRow-root:nth-child(even){
  background-color: lightgray;
} */

tr[level="0"].MuiTableRow-root:nth-child(even){
  background-color: lightgray;
}
      

::-webkit-scrollbar:horizontal{
  height: 12px !important;
  width: 12px !important;
  border-radius: 10px !important;
  
  
}

::-webkit-scrollbar-thumb:horizontal {
  background: gray !important; 
}
.uploadTitle{
  text-align: center;
}

.errorMsg{
  margin-left:0% !important;
}
.MuiTableCell-root  {
    padding: 0 6px !important;
    font-size:12px !important;
    /* white-space: 'nowrap' !important;
  text-overflow: 'ellipsis' !important;
  overflow: 'hidden' !important;
  width: '350px' !important;
  max-width: '350px' !important; */
    
  
}

.delete-div{
  font-size: 4px !important;
  margin-top: 6px !important;
}
.accordion-button{
  font-size: 1.1rem !important;
}
/* .MuiTableRow-root {
  white-space: 'nowrap' !important;
  text-overflow: 'ellipsis' !important;
  overflow: 'hidden' !important;
  width: '350px' !important;
  max-width: '350px' !important;
} */

 /* html {
  zoom: 0.8; Old IE only 
   -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}  */

.closeIcon{
  margin-bottom:8px !important;
  color:black !important;
  font-size:19px !important;
  cursor: pointer !important;
}


.filterSpan{
  
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.checkboxDiv{
  margin-left: 15px!important;
 margin-top: -29px!important
}
.MuiCheckbox-root{padding:0!important;}

.loaderDiv{
  text-align: center;
flex: 1 1;
justify-content: center;
margin-top: 150px !important;
/* opacity: 0.1; */
height: 100%;
width: 100%;
position: fixed;
z-index: 1;
left: 220px;

}

.RefundStyle{
  margin-left: 181px;
}
.chargebackStyle{
  margin-left:94px;
}
.css-1v5z18m {
  width: 180px !important;
}
.css-1domaf0{
width: 150px !important;
}
.css-yuob64{
  margin:0px !important
}
.css-yuob64{
  margin: 0px 50px -20px 25px !important;
}
.css-187mznn-MuiSlider-root {
  border-radius: 16px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #1976d2;
  -webkit-tap-highlight-color: transparent;
  height: 10px !important;
  width: 100% !important;
  padding: 15px 0;
}
/* .MuiSlider-root{
  position:fixed !important;
  width:8% !important;
  padding: 0 !important;
} */



.css-1nbic85 {
  margin-top: -10px !important;
}
/* .MuiSlider-valueLabelLabel{
  position: fixed !important;
  margin-top: 31px;
  color: black;
  margin-right: 11px;
  font-size: 12px;
  margin-left: -4px;
} */


.MuiSlider-valueLabelLabel {
  color: #000000 !important;
}

.MuiSlider-valueLabel {
  top: 30px !important;
}

.MuiSlider-valueLabel:before {
  top: -6px;
}

.popupFilterSection{
  display: flex;
  /* width: 1000px; */

}
.popupFilterSection .input-bar-item {
  padding: 20px;
}
.popupFilterSection .btn {
  position: absolute;
  right: 10px;
  bottom: 15px;
}
.popupFilterSection .closeModel {
  position: absolute;
  right: -16px;
  top: -14px;
}
.popupFilterSection > label{
  font-weight: bold;
}
.popupFilterElement{
  padding: 5px;
}
.addIconClassPoupUp{
  color:#337ab7;
  font-size: 30px;

}





.custom-file {
  width: 100px !important;
  height: 100px !important;
  border: 1px dotted #dedede;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  float: left;
  margin-right:-300px !important ;
}

.custom-file .placeholder {
  width: 40px !important;
  height: 40px !important;
  margin: 0 auto;
}

.custom-file{
  border: none !important;
}
.fileupload_div{
  /* margin-left: -400px; */
  float: left;
  /* left:0; */
  left: 260px;
  margin-top: 4px;
  position: fixed;

  width: 30%;
  /* display: flex;
  flex: 1;
  justify-content: flex-start; */
}
.fileupload_div_right{
  float: right;
  /* right:0; */
  /* margin-left: 520px; */
  right: 230px;
  margin-top: 10px;
  position: fixed;
  /* display: flex;
  flex: 1;
  justify-content: flex-end; */
}
/* .delete-div{
  margin-bottom: -16px !important;
} */

.uploadWatch {
  margin-left: 1255% !important;
}

/* #content-wrapper{
  top: 20px !important;
  overflow-x: hidden;
} */
.mt-8{
  margin-left: 10px;
}
.refreshIcon{
  transform: scale(2.2);
  cursor: pointer;

}
.uploadDownloadDivMidSec{
   /* margin-top: -30px !important;  */
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-right: 120px;
  /* margin-top: 20px !important; */
}

/* .uploadDownloadDivMidSec {
  margin-top: -40px !important;
  text-align: center;
  position: fixed;
  cursor: pointer;
  margin-right: 120px;
  margin-left: 420px;
} */

/* .uploadDownloadDivMidSec {
  margin-top: -40px !important;
  text-align: center;
  position: fixed;
  cursor: pointer;
  margin-right: 120px;
  margin-left: 420px;
} */

.uploadWatchSync{
  /* margin-right: 10px; */
  text-align: center;
  margin-bottom: 20px;
}
.messageSyncCom{
  text-align: center;
   margin-right: 70px; 
}
.topbar{
  margin-bottom: 50px;
}

/* .toopbar {
  display: flex;
  justify-content: space-between;
} */

/* .fileupload_div {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.fileupload_div_right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
} */

.mod_crud_section{

}

.mod_crud_section_input-bar {
  display: table;
  width: 20%;
}

.mod_crud_section_input-bar-item {
  display: table-cell;
}

.mod_crud_section_input-bar-item > button {
  margin-left: 5px;
}

.syncInfoIcon{
  transform: scale(1.1);
  color: #1976d2;
}
.syncInfoIconDiv{
  margin-bottom: 10px;
  margin-right: 50px;
}

.api-sync-com{
}
.muitooltip-popper{
 color: "white";
width: "max-content"; 
max-width: "200px";
}

/* .syncSelectDiv{
  margin-left: 300px;
} */
.async-select-sync{
  width: 149px !important;

}
.api-sync-com-wow{
  margin-left: 380px;
}
.errorMsgSync{
  margin-right: 80px;
}
.messageSyncComWow{
  text-align: center;
  margin-right: 245px; 
}
.wowSync{
  margin-right:150px;
}
.syncInfoIconDivWow{
  margin-bottom: 10px;
  margin-right: 25px;
}

/* .custom-file . */

.fileupload_div .text-center{
  text-align: left;
}

.viewScreen{
  margin-right: 200px;
}
.reportTypeStyle{
  margin-left: 28px;
}
.groupBtn{
  padding: 8px;
}