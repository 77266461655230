#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  /* background: #222; */
  background:#337ab7;
  transition: all 0.5s ease;
}

.sign-in-log ul{list-style: none;}
.sign-in-log ul li{display: inline-block;}
.sign-in-log ul li a{
      font-size: 16px;
    letter-spacing: 0.03em;
    font-weight: 600;
    color: #000 !Important;
    text-decoration: none;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

/**** start accordion_updown ****/
/*.accordion_updown{width: 100%; max-width: 360px; margin: 30px auto 20px; background: #fff; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px;}*/
.accordion_updown .link {cursor: pointer; display:block;color:#4D4D4D; font-size:14px; font-weight:700; position: relative; -webkit-transition: all 0.4s ease; -o-transition:all 0.4s ease;transition: all 0.4s ease;}
.accordion_updown li{border-bottom: 1px solid #ccc;}
.accordion_updown li:last-child{border-bottom: 1px solid #fff;}
.accordion_updown li:last-child .link { border-bottom: 0; }
.accordion_updown li i {position: absolute; top: 16px; left: 12px; font-size: 18px; color: #fff; -webkit-transition: all 0.4s ease; -o-transition: all 0.4s ease; transition: all 0.4s ease;}
.accordion_updown li i.fa-chevron-down {right: 12px; left: auto; font-size: 16px;}
.accordion_updown li.open .link { color: #fff; }
.accordion_updown li.open i { color: #b63b4d;}
.accordion_updown li.open i.fa-chevron-down {-webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -o-transform: rotate(180deg); transform: rotate(180deg);}
.submenu {display: none;list-style: none; font-size: 14px; padding:0px;}
/**** start accordion_updown ****/


.sign-in-log{float: right}
.navbar-header{width: 100%;padding:20px;}
.navbar-brand {
    float: left;
    height: 50px;
    padding: 0!Important;
    font-size: 18px;
    line-height: 20px;
}
.sidebar-brand {
  width: 270px;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}
.sidebar-brand h2 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}
.sidebar-brand h2 span{
      display: block;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0px 0px 0px;
}


.sidebar-nav {
  position: absolute;
  top: 90px;
  width: 270px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav > li {
  text-indent: 10px;
  line-height: 65px;
  border-bottom: 1px solid #ccc;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #757575;
  font-weight: 400;
    font-size: 14px;
}
.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #fff;
 /* background: #F8BE12;*/
}
.sidebar-nav > li > a i.fa {
  font-size: 24px;
  width: 60px;
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
#navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: #fdfdfd;
    font-size: 24px;
    margin-bottom: 0;
    height: 90px;
    box-shadow: 0px 0px 8px #c1bdbd;
    border-radius: 0;
}
#navbar-wrapper .navbar a {
  color: #757575;
}
#navbar-wrapper .navbar a i{
  font-size: 40px;
  color: #000;
}
#navbar-wrapper .navbar a:hover {
  color: #F8BE12;
}


#content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}
#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.contant-sec{
  box-shadow: -1px 1px 5px #696868;
  padding: 20px 0px;
}
.contant-sec .content-title{
  margin: 0px;
    font-size: 18px;
    letter-spacing: 0.03em;
    color: #000;
    font-weight: 600;
    border-bottom: 1px solid #000;
   padding: 0px 20px 15px;
}
.contant-sec p{
  margin: 0px;
}
.filters-opt{
  padding: 15px 20px 15px;
   border-bottom: 1px solid #000;
}
.filters-opt span{
      font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03em;
    color: #000;
}
.filters-opt ul{
  display:inline-block;
  list-style: none;
}
.filters-opt ul li{
      border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    letter-spacing: 0.03em;
    display: inline-block;
    margin-right: 10px;
}
.filters-opt ul li i{padding-left:40px}

.apply-btn{
   float: right;
    background: #000;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 31px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.03em;
    display: inline-block;
    margin-right: 10px;
}






/* Popup Box Css Start */

.bg-back{
  background:#337ab7;
  padding:170px 0px 0px 0px;
  height: 100vh;
}

.overlay-bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 1000; /* high z-index */
  background: #000; /* fallback */
  background: rgba(0, 0, 0, 0.90);
}
.overlay-content {
  /*display: none;*/
  background: #fff;
  padding: 2%;
  width: 35%;
  /*position: absolute;
  top: 15%;
  left: 57%;*/
  margin: 0 auto;
  cursor: default;
  z-index: 10001;
  border-radius:10px;
  /* box-shadow: 0 0 34px 0px rgb(0 0 0 / 20%); */
}

.close-btn {
  cursor: pointer;
  border: 1px solid #333;
  padding: 2% 5%;
  background: #333;
  width: 40%;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.03em;
  display: block;
  margin: 60px auto 0px;
  border-radius: 7px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
.overlay-content h1{
  font-size: 24px;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.03em;
  margin: 0px 0px 15px;
}

.field {
    position: relative;
    height: 45px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    background: rgba(255,255,255,0.94);
}
.field input {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid #ccc;
    color: #222;
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
}
.field span {
    color: #222;
    /*width: 40px;*/
    font-size: 20px;
    position: absolute;
    right: 0;
    line-height: 45px;
}
.forgot-pss{
  font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.03em;
    float: right;
    display: block;
    margin-top: 20px;
}

.close-btn:hover {
  background: #333;
}

/* sidebar navigation starts */
.sidebar-menu-accordian .accordion .accordion-item{
  /* background-color: #222222; */
  background-color: #337ab7;
  border-bottom:1px solid #ffffff;
}

.sidebar-menu-accordian .accordion .accordion-item .accordion-header .accordion-button{
  font-size: 1.25rem;
  /* background-color: #222222; */
  background-color: #337ab7;
  color: #ffffff;
  padding: 1.5rem 2rem;
  /* border-bottom: 1px solid #ffffff; */
}

.sidebar-menu-accordian .accordion .accordion-item .accordion-header .accordion-button::after{
  fill: #ffffff;
  background-image: url('./assets/arrow-up.png');
}

.sidebar-menu-accordian .accordion .accordion-item .accordion-header .accordion-button:focus{
  box-shadow: unset;
}

.sidebar-menu-accordian .accordion .accordion-item .sidebar-child-menu .list-item a{
  padding: 0.75rem;
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.125rem;
  opacity: 0.7;
  transition: all ease-in-out 300ms;
}
.sidebar-menu-accordian .accordion .accordion-item .sidebar-child-menu .list-item a:hover{
  opacity: 1;
  transition: all ease-in-out 300ms;
}
/* sidebar navigation ends */

/* media query for most mobile devices */
@media only screen and (min-width: 0px) and (max-width: 480px) {
  .overlay-content {
    width: 96%;
    margin: 0 2%;
    left: 0;
  }
}



/* Popup Box Css End */


@media (min-width: 992px) {
  #wrapper {
    padding-left: 270px;
  }
  
  #wrapper.toggled {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 270px;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 0px;
  }
  
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 0px;
  }

  #content-wrapper {
    position: relative;
    top: 5px;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #wrapper {
    padding-left: 0px;
  }

  #sidebar-wrapper {
    width: 0px;
  }
  
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

@media (max-width: 767px) {
  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}
@media (min-width: 1200px){
  

  #sidebar-toggle{display:none;}
}