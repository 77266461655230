.homelogoicon{
    cursor: pointer;
}

.sidebar-menu-accordian .accordion .accordion-item .sidebar-child-menu span {
    padding: 0.75rem;
    display: block;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.125rem;
    opacity: 0.7;
    transition: all ease-in-out 300ms;
    font-size: 1.25rem;
    background-color: #222222;
    color: #ffffff;
    padding: 1.5rem 2rem;
    /* border-bottom: 1px solid #ffffff; */
}

.accordion-button {
    font-size: 1.25rem;
    background-color: #222222;
    color: #ffffff;
    padding: 1.5rem 2rem;
    /* border-bottom: 1px solid #ffffff; */
}

.mrleft15{
    margin-left: 15px;
}
.sidebarBorder{
border-bottom: 1px solid #ffffff;
}