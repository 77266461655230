.pointerCls{
    cursor: pointer;
}
.welcome-cls{
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.reportMainHome{
    font-size: 14px;
}
.homeReportRow{
    text-align: center;
}
.reportMainHome .header{
font-weight: bold;
}
.alignLeft{
    text-align: left;
}
.alignRight{
    text-align: right;
}
.endDateStyle_home{
    margin-left: 88px;
}
.refundStyle_home{
    margin-left: 198px;
}

.refundStyle_home_G{
    margin-left: 86px;
}
.chargebackStyle_home{
    margin-left: 42px;
}
.chargebackStyle_home_G{
    margin-left: 46px;
}

.bs-linebreak{
    height:10px;
}
.homeReportTitle{
    font-size: 20px;
}
.mr30{
    margin-right: 30px;
}
.width142x{
    width: 142px;
}
/* .MuiSlider-root{
   width:12% !important;
   padding: 0 !important;
 } */
  .step_home{
    margin-left: 122px;
  }

  .range_home{
    margin-left: 15px;
  }
  .center-align{
    text-align: center;
  }
  .width100x{
    width: 100px;
  }
  .width90x{
    width: 90px;
  }
  .width80x{
    width: 80px;
  }
  .width50x{
    width: 50px;
  }
  .width70x{
    width: 70px;
  }

  .loaderGraph{
    margin-left: -190px;
  }


  /* Style the checkbox container */
.checkbox-container {
    margin: 20px 0;
  }
  
  /* Style the checkbox label */
  .checkbox-label {
    display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
  }
  
  /* Hide the default checkbox */
  .checkbox-label input[type="checkbox"] {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create the custom checkbox */
  .checkbox-label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  /* Checkbox colors for each label */
  .checkbox-label.checkbox1::before {
    border-color: green; /* Green */
  }
  
  .checkbox-label.checkbox2::before {
    border-color: #ff5722; /* Orange */
  }
  
  .checkbox-label.checkbox3::before {
    border-color: #2196f3; /* Blue */
  }
  
  /* When the checkbox is checked, change the background color */
  .checkbox-label input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: currentColor;
    color: #fff;
  }
  
  .checkbox-label.checkbox1 input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: black; /* Green */
    border-color:black
  }
  
  .checkbox-label.checkbox2 input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: #ff5722; /* Orange */
  }
  
  .checkbox-label.checkbox3 input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: #2196f3; /* Blue */
  }
  
  /* Create a checkmark symbol inside the checkbox when checked */
  .checkbox-label input[type="checkbox"]:checked + .checkbox-label::after {
    content: '\2713'; /* Unicode checkmark symbol */
    position: absolute;
    left: 4px;
    top: 0px;
    font-size: 16px;
    font-weight: bold;
    color:black
  }
  
  /* Hover effect */
  .checkbox-label:hover::before {
    background-color: #ddd;
  }
  
  /* When checkbox is unchecked, the background is white */
  .checkbox-label input[type="checkbox"]:not(:checked) + .checkbox-label::before {
    background-color: black
  }
  